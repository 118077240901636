<template>
    <div>
        <card-header title="Participant Flights" icon="fa-plane"/>

        <card-body>
            <card-list>
                <container-list-item padded class="pt-3 pb-3">
                    <div class="columns">
                        <div class="column">
                            <b-field>
                                <b-select v-model="filter" icon="filter" expanded>
                                    <option value="all">All Flights</option>
                                    <option value="depart">By Departure Airport</option>
                                    <option value="arrive">By Arrival Airport</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field>
                                <b-select v-model="groupBy" icon="people-group" expanded>
                                    <option value="none">No Grouping</option>
                                    <option value="passenger">Group by Passenger</option>
                                    <option value="depart">Group by Departure Airport</option>
                                    <option value="arrive">Group by Arrival Airport</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>

                    <b-field>
                        <b-select v-model="airport" icon="plane" expanded v-if="filter==='arrive'">
                            <option :value="a.id" v-for="a in arriveAirports">{{ a.title }}</option>
                        </b-select>
                        <b-select v-model="airport" icon="plane" expanded v-if="filter==='depart'">
                            <option :value="a.id" v-for="a in departAirports">{{ a.title }}</option>
                        </b-select>
                    </b-field>

                    <b-field v-if="filter!=='primary'">
                        <b-switch v-model="primaryOnly">Show only Primary Flights</b-switch>
                    </b-field>
                </container-list-item>


                <template v-for="group in filteredFlights">
                    <subheader-list-item :title="group.title" :icon="group.icon"/>

                    <flight-list-item
                        :card="card"
                        :flight="flight"
                        v-for="flight in group.flights"
                        :show-dates="true"
                        show-name
                        :active="child && child.definition.component === 'edit-flight' && child.props.flightId === flight.id.toString()"
                        @click="$openCard('edit-flight', {registrationId: flight.registration_id, flightId: flight.id}, card)"
                    />

                    <template v-if="groupBy==='passenger' && group.flights.length === 0">
                        <navigation-list-item
                            icon="fa-suitcase"
                            title="Registration"
                            :active="(child && child.definition.component === 'registration') && child.props.registrationId === group.registration_id.toString()"
                            @click="$openCard('registration', {registrationId: group.registration_id}, card)"
                        ></navigation-list-item>
                        <container-list-item padded>
                            <div class="has-text-grey is-italic" v-if="filter==='all' && !primaryOnly"><i class="fal fa-question-circle mr-2 ml-2 fa-fw"></i>No flights for this passenger.</div>
                            <div class="has-text-grey is-italic" v-else><i class="fal fa-question-circle mr-2 ml-2 fa-fw"></i>No matching flights for this passenger for this filter.</div>
                        </container-list-item>
                    </template>

                </template>
            </card-list>
        </card-body>
        <card-footer>
            Showing <strong>{{visibleFlights}}</strong> of <strong>{{flights.length}}</strong> total flights.
        </card-footer>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';

    import CardHeader from '@/TIER/components/CardHeader';
    import CardToolbar from '@/TIER/components/CardToolbar';
    import CardBody from '@/TIER/components/CardBody';
    import CardList from '@/TIER/components/CardList';
    import FlightListItem from '../../components/FlightListItem';
    import RegistrationListItem from "@/components/RegistrationListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import TripListItem from "../../components/TripListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import async from 'async';
    import NavigationListItem from "../../TIER/components/NavigationListItem.vue";
    import CardFooter from "../../TIER/components/CardFooter.vue";

    export default {
        props: ['card', 'child', 'props'],
        components: {CardFooter, NavigationListItem, ContainerListItem, TripListItem, SeparatorListItem, SubheaderListItem, RegistrationListItem, FlightListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                registrations: [],
                arriveAirports: [],
                departAirports: [],
                flights: [],
                flightGroups: [],
                filter: 'all',
                groupBy: 'none',
                primaryOnly: false,
                airport: null
            };
        },
        computed: {
            passengers: function() {
                let passengers = [];
                for (let i = 0; i < this.registrations.length; i++) {
                    const registration = this.registrations[i];
                    passengers.push({
                        id: registration.user_id,
                        last_name: registration.user.last_name,
                        first_name: registration.user.first_name,
                        title: registration.user.last_name + ', ' + registration.user.first_name,
                        registration_id: registration.id
                    });
                    if (registration.second_user) {
                        passengers.push({
                            id: registration.second_user.id,
                            last_name: registration.second_user.last_name,
                            first_name: registration.second_user.first_name,
                            title: registration.second_user.last_name + ', ' + registration.second_user.first_name,
                            registration_id: registration.id
                        });
                    }
                }
                // sort by last_name
                passengers.sort((a, b) => (a.last_name > b.last_name) ? 1 : -1);
                return passengers;
            },
            visibleFlights: function() {
                let count = 0;
                for (let i = 0; i < this.filteredFlights.length; i++) {
                    count += this.filteredFlights[i].flights.length;
                }
                return count;
            },
            filteredFlights: function() {
                // do filters
                let filteredFlights;

                if (this.filter === 'all') {
                    filteredFlights = this.flights;
                } else if (this.filter === 'arrive') {
                    filteredFlights = this.flights.filter(f => f.arrive_airport.id === this.airport);
                } else if (this.filter === 'depart') {
                    filteredFlights = this.flights.filter(f => f.depart_airport.id === this.airport);
                }

                if (this.primaryOnly) {
                    filteredFlights = filteredFlights.filter(f => f.primary_flight);
                }

                // Do groups
                let groups = [];

                if (this.groupBy === 'none') {
                    let flights = filteredFlights.sort((a, b) => (a.arrive_at > b.arrive_at) ? 1 : -1);
                    flights.sort((a, b) => (a.depart_at > b.depart_at) ? 1 : -1);
                    groups.push({
                        title: 'Flights',
                        icon: 'fa-plane',
                        flights: flights
                    });
                } else if (this.groupBy === 'passenger') {
                    this.passengers.forEach(p => {
                        let passengerFlights = filteredFlights.filter(f => f.user_id === p.id);
                        passengerFlights.sort((a, b) => (a.depart_at > b.depart_at) ? 1 : -1);
                        groups.push({
                            title: p.title,
                            icon: 'fa-user',
                            flights: passengerFlights,
                            user_id: p.id,
                            registration_id: p.registration_id
                        });
                    });
                } else if (this.groupBy === 'arrive') {
                    this.arriveAirports.forEach(a => {
                        let arriveFlights = filteredFlights.filter(f => f.arrive_airport.id === a.id);
                        arriveFlights.sort((a, b) => (a.arrive_at > b.arrive_at) ? 1 : -1);
                        groups.push({
                            title: a.title,
                            icon: 'fa-plane-arrival',
                            flights: arriveFlights
                        });
                    });
                } else if (this.groupBy === 'depart') {
                    this.departAirports.forEach(a => {
                        let departFlights = filteredFlights.filter(f => f.depart_airport.id === a.id);
                        departFlights.sort((a, b) => (a.depart_at > b.depart_at) ? 1 : -1);
                        groups.push({
                            title: a.title,
                            icon: 'fa-plane-departure',
                            flights: departFlights
                        });
                    });
                }

                return groups;
            }
        },
        methods: {
            loadFlights: function(force) {
                this.$emit('loading', true);

                async.series([
                    // get all registrations
                    (cb) => {
                        http.get('/api/trips/' + this.props.tripId + '/registrations/0/100', {force}).then(response => {
                            this.registrations = response.data.registrations.filter(r => r.type !== 'cancelled');
                            cb();
                        });
                    },
                    // get all flights
                    (cb) => {
                        http.get('/api/trips/' + this.props.tripId + '/flights', {force}).then(response => {
                            let flights = response.data;

                            let arriveAirports = {};
                            let departAirports = {};
                            for (let i = 0; i < flights.length; i++) {
                                const flight = flights[i];
                                arriveAirports[flight.arrive_airport.id] = flight.arrive_airport.iata + ' - ' + flight.arrive_airport.name + ', ' + flight.arrive_airport.city + ', ' + flight.arrive_airport.country;
                                departAirports[flight.depart_airport.id] = flight.depart_airport.iata + ' - ' + flight.depart_airport.name + ', ' + flight.depart_airport.city + ', ' + flight.depart_airport.country;

                                if (flight.applies_to === 'primary') {
                                    this.flights.push({
                                        ...flight,
                                        first_name: flight.registration.user.first_name,
                                        last_name: flight.registration.user.last_name,
                                        user_id: flight.registration.user.id
                                    });
                                } else if (flight.applies_to === 'secondary') {
                                    this.flights.push({
                                        ...flight,
                                        first_name: flight.registration.second_user.first_name,
                                        last_name: flight.registration.second_user.last_name,
                                        user_id: flight.registration.second_user.id
                                    });
                                } else if (flight.applies_to === 'both') {
                                    this.flights.push({
                                        ...flight,
                                        first_name: flight.registration.user.first_name,
                                        last_name: flight.registration.user.last_name,
                                        user_id: flight.registration.user.id
                                    });
                                    this.flights.push({
                                        ...flight,
                                        first_name: flight.registration.second_user.first_name,
                                        last_name: flight.registration.second_user.last_name,
                                        user_id: flight.registration.second_user.id
                                    });
                                }
                            }

                            for (const id in arriveAirports) {
                                this.arriveAirports.push({id: parseInt(id), title: arriveAirports[id]});
                            }
                            for (const id in departAirports) {
                                this.departAirports.push({id: parseInt(id), title: departAirports[id]});
                            }

                            this.arriveAirports.sort((a, b) => (a.title > b.title) ? 1 : -1);
                            this.departAirports.sort((a, b) => (a.title > b.title) ? 1 : -1);

                            cb();
                        });
                    },
                ], err => {
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadFlights(true);
            }
        },
        mounted() {
            this.loadFlights();
        }
    };
</script>
